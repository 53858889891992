import React from "react";
import {
  Paper,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Login from "../../components/Login/Login";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      ...theme.mixins.gutters(),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(),
      boxSizing: "border-box",
      width: "90%",
      maxWidth: "600px",
      margin: "20px auto 0",
    },
  });

const LoginPage: React.FunctionComponent<WithStyles<typeof styles>> = ({
  classes,
}) => {
  return (
    <div>
      <Paper elevation={1} className={classes.root}>
        <Login />
      </Paper>
    </div>
  );
};

export default withStyles(styles)(LoginPage);

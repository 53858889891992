import React, { FunctionComponent, useState } from "react";
import {
  Grid,
  Typography,
  Theme,
  WithStyles,
  withStyles,
  Button,
  TextField,
} from "@material-ui/core";
import { useFirestore } from "reactfire";

const styles = (theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(2),
  },
});

const AddYeastLogPage: FunctionComponent<WithStyles<typeof styles> & any> = ({
  classes,
  history,
}) => {
  const [date, setDate] = useState("");
  const [hydroSG, setHydroSG] = useState("");
  const [refractoSG, setRefractoSG] = useState("");
  const [refractoBrix, setRefractoBrix] = useState("");
  const yeastLogs = useFirestore().collection("yeast-logs");

  const onSubmit = (event: any) => {
    event.preventDefault();
    yeastLogs
      .add({
        date: date,
        hydroSG: hydroSG,
        refractoSG: refractoSG,
        refractoBrix: refractoBrix,
      })
      .then((res: any) => {
        history.push(`/brew/${res.id}`);
      })
      .catch((e: any) => {
        alert(e.message);
      });
  };

  return (
    <Grid container>
      <Grid item xs className={classes.gridItem}>
        <Typography variant="subtitle1">Voeg brouw toe</Typography>
        <form onSubmit={onSubmit}>
          <TextField
            // id="standard-password"
            label="Datum"
            type="date"
            // className={classes.textField}
            value={date}
            onChange={(e) => setDate(e.target.value)}
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            // id="standard-name"
            label="SG"
            // className={classes.textField}
            value={hydroSG}
            onChange={(e) => setHydroSG(e.target.value)}
            margin="normal"
            fullWidth
          />
          <Typography variant="body1">Hydro</Typography>
          <TextField
            // id="standard-name"
            label="SG"
            // className={classes.textField}
            value={refractoSG}
            onChange={(e) => setHydroSG(e.target.value)}
            margin="normal"
            fullWidth
          />
          <Typography variant="body1">Refracto</Typography>
          <TextField
            // id="standard-name"
            label="SG"
            // className={classes.textField}
            value={refractoSG}
            onChange={(e) => setRefractoSG(e.target.value)}
            margin="normal"
            fullWidth
          />
          <TextField
            // id="standard-name"
            label="BRIX"
            // className={classes.textField}
            value={refractoBrix}
            onChange={(e) => setRefractoBrix(e.target.value)}
            margin="normal"
            fullWidth
          />

          <Button
            variant="contained"
            size="large"
            color="primary"
            type="submit"
          >
            Aanmaken
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddYeastLogPage);

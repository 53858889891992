import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useParams } from "react-router";
import { useFirestore } from "reactfire";

const AddYeastLogDialog = () => {
  // Dialog
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Form
  const [date, setDate] = useState("");
  const [sg, setSg] = useState("");
  const [brix, setBrix] = useState("");
  const yeastLogsRef = useFirestore().collection("yeast-logs");
  const { brewId } = useParams();

  const onSave = () => {
    yeastLogsRef
      .add({
        brew: brewId,
        date: new Date(date),
        hydroSG: sg,
        refractoBrix: brix,
      })
      .then((res: any) => {
        handleClose();
      })
      .catch((e: any) => {
        alert(e.message);
      });
  };

  return (
    <>
      <Button onClick={handleOpen} color="primary">
        Log toevoegen
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="add-yeast-dialog-title">Voeg gistlog toe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Vul hier de waardes van de hydro- en refractometer in.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="date"
            label="Datum"
            type="date"
            fullWidth
            value={date}
            onChange={(event) => setDate(event.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="sg"
            label="SG"
            type="text"
            fullWidth
            value={sg}
            onChange={(event) => setSg(event.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="brix"
            label="Brix"
            type="text"
            fullWidth
            value={brix}
            onChange={(event) => setBrix(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={onSave}
            color="primary"
            disabled={!sg && !date && !brix}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddYeastLogDialog;
